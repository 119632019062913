require ('../../styles/base/cursor.scss');

// CURSOR
class Cursor {
	constructor(options = {
		cursor : '.v-cursor',
		dot    : '.v-dot',
		links  : 'c-link',
		buttons: 'c-btn',
		images : 'c-img'
	}) {
		if (!options.links && options.links !== false)
			options.links = 'c-link'
		if (!options.buttons)
			options.buttons = 'c-btn'
		if (!options.images)
			options.images = 'c-img'
		this.options = options
		this.cursor = document.querySelector(`${options.cursor}`)
		this.dot   = document.querySelector(`${options.dot}`)

		window.addEventListener('mouseover', () => {
			this.cursor.style.opacity = '1'
			this.dot.style.opacity = '1'
		})

		window.addEventListener('mousemove', (e) => {
			let X = e.x, Y = e.y,
				rect = this.cursor.getBoundingClientRect(),
				rectd = this.dot.getBoundingClientRect()
			this.cursor.style.top = Y + 'px'
			this.cursor.style.left = X + 'px'

			this.dot.style.top = Y + 'px'
			this.dot.style.left = X + 'px'
		})

		window.addEventListener('mouseout', () => {
			this.cursor.style.opacity = '0'
			this.dot.style.opacity = '0'
		})

		window.addEventListener('mousedown', () => {
			this.cursor.style.transform = 'translate(-50%, -50%) scale(0.7)'
		})

		window.addEventListener('mouseup', () => {
			this.cursor.style.transform = 'translate(-50%, -50%) scale(1)'
		})

		if (options.links) {
			let arr = document.querySelectorAll(`.${options.links}`)

			arr.forEach((el) => {
				el.addEventListener('mouseover', () => {
					this.makeLink()
				})
				el.addEventListener('mouseout', () => {
					this.reset()
				})
			})
		}

		if (options.buttons) {
			let arr = document.querySelectorAll(`.${options.buttons}`)

			arr.forEach((el) => {
				el.addEventListener('mouseover', () => {
					this.makeBtn()
				})
				el.addEventListener('mouseout', () => {
					this.reset()
				})
			})
		}

		if (options.images) {
			let arr = document.querySelectorAll(`.${options.images}`)

			arr.forEach((el) => {
				el.addEventListener('mouseover', () => {
					this.makeImg()
				})
				el.addEventListener('mouseout', () => {
					this.reset()
				})
			})
		}
	}

	makeLink() {
		this.cursor.classList.add('link')
		this.dot.classList.add('link')
	}

	makeBtn() {
		this.cursor.classList.add('button')
		this.dot.classList.add('button')
	}

	makeImg() {
		this.cursor.classList.add('img')
		this.dot.classList.add('img')
	}

	reset() {
		this.cursor.classList.remove('link')
		this.dot.classList.remove('link')
		this.cursor.classList.remove('button')
		this.dot.classList.remove('button')
		this.cursor.classList.remove('img')
		this.dot.classList.remove('img')
	}
}

let c = new Cursor({
	cursor: '.v-cursor',
	dot   : '.v-dot',
})

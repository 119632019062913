/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)

// GENERAL
// import '../../styles/base/app.scss';

require ('./construction.js')
require ('./cookies.js')
require ('./cursor.js')
require ('./flash-message.js')
require ('./get-in-touch.js')
require ('./footer.js')
require ('./header.js')
require ('./orientation.js')

// console.log(c.options)
